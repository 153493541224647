import gql from 'graphql-tag'

export const EndpointCreateFragment = gql`
fragment EndpointCreate on Endpoint {
  _id
  environmentId
  tokens
}
`

export const EndpointUpdateFragment = gql`
fragment EndpointUpdate on Endpoint {
  identifier
  name
  collectionId
  indicatorId
  filterId
  type
  formId
  requireToken
}
`

export const EndpointFragment = gql`
fragment Endpoint on Endpoint {
  ...EndpointCreate
  ...EndpointUpdate
}
${EndpointCreateFragment}
${EndpointUpdateFragment}`
